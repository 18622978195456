<template>
  <div id="CoursesEnrolled">
    <div class="container" style="min-height: 150px">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-11">
              <h1><b-icon icon="people" /> Personas de {{ desserts.name }}</h1>
            </div>
            <div class="col-1">
              <h1>
                <b-icon
                  type="button"
                  @click="toCourse"
                  icon="arrow-return-left"
                />
              </h1>
            </div>
          </div>
        </div>
        <div class="card-body">
          <b-row>
            <b-col><EnrolledRegister :idCourse="id" /></b-col>
            <b-col class="mb-3">
              <b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                <b-input-group-prepend is-text>
                  <b-icon icon="search"></b-icon>
                </b-input-group-prepend>
                <b-form-input
                  v-model="keyword"
                  placeholder="Buscar"
                  type="text"
                />
              </b-input-group>
            </b-col>
          </b-row>
          <div v-if="desserts.list.length != 0">
            <b-table
              striped
              responsive
              stacked="lg"
              :items="items"
              :fields="fields"
              :per-page="perPage"
              :current-page="currentPage"
              :keyword="keyword"
            >
              <template #cell(names)="row">
                <p>
                  {{
                    row.item.lastnameFather +
                    " " +
                    row.item.lastnameMother +
                    ", " +
                    row.item.name
                  }}
                </p>
              </template>
              <template #cell(foulPercentage)="row">
                <b v-if="row.item.foulPercentage == null">-</b>
                <b class="text-danger" v-else-if="row.item.foulPercentage > 30">
                  {{ row.item.foulPercentage + "%" }}
                </b>
                <b class="text-success" v-else>{{
                  row.item.foulPercentage + "%"
                }}</b>
              </template>
            </b-table>

            <b-pagination
              style="display: flex; justify-content: center"
              v-model="currentPage"
              pills
              :total-rows="items.length"
              :per-page="perPage"
              aria-controls="my-table"
            ></b-pagination>
            <center>
              <b-button variant="primary" @click="exportExcel"
                >Reporte Asistencia</b-button
              >
            </center>
          </div>

          <div v-else>
            <hr />
            <em><p class="text-center">No hay datos en esta tabla</p></em>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as XLSX from "xlsx/xlsx.mjs";

/* load 'fs' for readFile and writeFile support */
import * as fs from "fs";
XLSX.set_fs(fs);

/* load 'stream' for stream support */
import { Readable } from "stream";
XLSX.stream.set_readable(Readable);

/* load the codepage support library for extended support with older formats  */
import * as cpexcel from "xlsx/dist/cpexcel.full.mjs";
XLSX.set_cptable(cpexcel);

import EnrolledRegister from "./Enrolled/Enrolled-register.vue";
export default {
  name: "CoursesEnrolled",
  components: { EnrolledRegister },
  props: {
    id: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      keyword: "",
      perPage: 10,
      currentPage: 1,
      desserts: { list: [] },
      fields: [
        { key: "number", label: "#" },
        { key: "names", label: "Apellidos, Nombres" },
        { key: "role", label: "Rol" },
        { key: "foulPercentage", label: "Inasistencia", sortable: true },
        { key: "phone", label: "Celular" },
      ],
    };
  },
  created() {
    this.initialize();
  },
  computed: {
    rows() {
      return this.desserts.list.length;
    },
    items() {
      this.currentPage = 1;
      return this.keyword
        ? this.desserts.list.filter(
            (item) =>
              item.name.toLowerCase().includes(this.keyword.toLowerCase()) ||
              item.lastnameFather
                .toLowerCase()
                .includes(this.keyword.toLowerCase()) ||
              item.lastnameMother
                .toLowerCase()
                .includes(this.keyword.toLowerCase()) ||
              item.role.toLowerCase().includes(this.keyword.toLowerCase())
          )
        : this.desserts.list;
    },
  },
  methods: {
    toCourse() {
      this.$router.go(-1);
    },
    exportExcel() {
      var temporal = this.desserts.list;
      //Quitar imagen
      for (let index = 0; index < temporal.length; index++) {
        delete temporal[index].id;
        delete temporal[index].number;
      }
      let data = XLSX.utils.json_to_sheet(temporal);
      data["A1"].v = "NOMBRES";
      data["B1"].v = "AP. PATERNO";
      data["C1"].v = "AP. MATERNO";
      data["D1"].v = "ROL";
      data["E1"].v = "INASISTENCIA(%)";
      data["F1"].v = "CELULAR";
      const workbook = XLSX.utils.book_new();
      const filename = "asistencia";
      XLSX.utils.book_append_sheet(workbook, data, filename);
      XLSX.writeFile(workbook, this.desserts.name + "_ReporteExcel.xlsx");
    },
    async initialize() {
      await this.$axios
        .get("/academic/course/" + this.id + "/enrolled", {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((res) => {
          this.desserts = res.data;
          for (let index = 0; index < this.desserts.list.length; index++) {
            this.desserts.list[index].number = index + 1;
          }
        })
        .catch((error) => {
          this.$errorQuery(error);
        });
    },
  },
};
</script>
<style>
.card-header {
  background-color: rgb(119, 35, 45);
  color: white;
}
</style>