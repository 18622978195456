<template>
  <div id="EnrolledRegister">
    <button
      @click="addPerson()"
      style="background-color: #1d425e; color: white"
      class="btn mb-3"
    >
      Agregar persona
    </button>
    <div>
      <b-modal scrollable centered v-model="show" title="Registro de personal">
        <b-container fluid>
          <!--Contenido modal-->
          <AlertError ref="error" />
          <b-overlay :show="showOverlay" rounded="sm">
            <b-form-group label="Buscar usuario:" class="my-2">
              <b-input-group>
                <b-form-input
                  v-if="selected == null"
                  type="text"
                  v-model="text"
                  placeholder="Ingrese nombre"
                >
                  <!--@keydown="inquiry()"--></b-form-input
                >
                <b-form-input
                  v-else
                  type="text"
                  placeholder="Ingrese nombre"
                  disabled
                  :value="selected.names"
                ></b-form-input>
                <b-input-group-append
                  @click="deselect()"
                  v-if="selected != null"
                >
                  <b-form-checkbox button button-variant="primary"
                    ><b-icon-x />
                  </b-form-checkbox>
                </b-input-group-append>
                <b-input-group-append v-else>
                  <b-button @click="inquiry()"><b-icon-search /></b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <div v-show="selected == null && options.length != 0">
              <b-form-select
                v-model="selected"
                :options="options"
                :select-size="tamOptions(options.length)"
              ></b-form-select>
            </div>
          </b-overlay>
          <b-form-group label="Seleccionar rol:" class="my-2">
            <b-form-select v-model="selectedRole" :options="optionsRole" />
          </b-form-group>
        </b-container>

        <template #modal-footer>
          <div class="w-100">
            <button
              type="button"
              @click="addData()"
              style="background-color: #1d425e; border: none; color: white"
              class="btn form-control my-2"
            >
              Enviar
            </button>
            <button
              type="button"
              @click="exit()"
              style="
                background-color: rgb(119, 35, 45);
                border: none;
                color: white;
              "
              class="btn form-control"
            >
              Cancelar
            </button>
          </div>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
import AlertError from "../../../components/AlertError.vue";
export default {
  name: "EnrolledRegister",
  components: { AlertError },
  props: {
    idCourse: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      showOverlay: false,
      show: false,
      selected: null,
      selectedRole: null,
      text: "",
      options: [],
      optionsRole: [],
      addPersonData: {},
    };
  },
  created() {
    this.ListRoles();
  },
  methods: {
    tamOptions(data) {
      if (data == 1) return 2;
      else return data;
    },
    async addData() {
      this.addPersonData.personId = this.selected.id;
      this.addPersonData.roleId = Number(this.selectedRole);
      await this.$axios
        .post(
          "/academic/course/" + this.idCourse + "/enrolled-register",
          this.addPersonData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.token}`,
            },
          }
        )
        .then((res) => {
          this.show = false;
          this.$message.success(res.data.content);
          this.$parent.initialize();
        })
        .catch((e) => {
          console.log(e);
          this.$refs.error.showAlert(e.response.data.content);
        });
    },
    async ListRoles() {
      await this.$axios
        .get("/academic/course/" + this.idCourse + "/enrolled-register", {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((res) => {
          this.optionsRole = res.data;
        })
        .catch((error) => {
          this.$errorQuery(error);
        });
    },
    async inquiry() {
      this.showOverlay = true;
      this.options = [];
      this.inquiryValided();
    },
    async inquiryValided() {
      await this.$axios
        .get("/person/search?names=" + this.text, {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((res) => {
          for (let i = 0; i < res.data.length; i++) {
            var aux = {};
            aux.text = res.data[i].names + " (" + res.data[i].email + ")";
            aux.value = res.data[i];
            this.options.push(aux);
          }
          this.showOverlay = false;
        })
        .catch((error) => {
          this.$errorQuery(error);
        });
    },
    deselect() {
      this.options = [];
      this.selected = null;
      this.text = null;
    },
    addPerson() {
      //ojo
      this.text = "";
      this.selected = null;
      this.selectedRole = null;
      this.addPersonData = {};
      this.show = true;
      this.options = [];
    },
    exit() {
      this.show = false;
    },
  },
};
</script>
<style>
.modal-content {
  background-color: rgb(243, 243, 243);
}
</style>